import React from "react";

import codyImage from "../assets/assets1.svg";
import ralphImage from "../assets/assets2.svg";
import marwinImage from "../assets/assets3.svg";
import wadeImage from "../assets/assets4.svg";
import amyImage from "../assets/assets5.svg";
import HeartIcon from "../assets/Blue Heart.svg"; // Ensure this is the correct path

import CommentCard from "./CommentCard";

const testimonialsTop = [
  {
    author: "Zhuowen Li",
    content:
      "“Since using Explore Ease, I no longer worry about planning my trips. It saves me a lot of time.”",
    image: codyImage,
  },
  {
    author: "Emma Williams",
    content:
      "“I recommend Explore Ease to all who love traveling. It's a great helper for planning trips, especially for arranging complex itineraries.”",
    image: ralphImage,
  },
  {
    author: "Jamal Brown",
    content:
      "“It is a must-have for traveling! Its recommendations for local experiences are always so accurate, making every trip full of surprises.”",
    image: marwinImage,
  },
  {
    author: "George Anderson",
    content:
      "“There are new discoveries. The user interface is very friendly and the features are comprehensive.”",
    image: wadeImage,
  },
  {
    author: "Isabella Martinez",
    content:
      "“Arranging trips with roommates has become easier. Sharing the link with friends with one click. ”",
    image: amyImage,
  },
];

const TestimonialsSection: React.FC = () => {
  return (
    <div
      id="testimonial"
      className="py-12 overflow-hidden px-36 mt-20 mb-4 text-xs"
    >
      <div className="flex text-center justify-center py-4 relative mt-12 z-10">
        <div className="text-xl text-textblue" style={{ maxWidth: "100%" }}>
          TESTIMONIAL
        </div>
      </div>
      <div className="flex text-center mb-12 justify-center my-8 relative z-10 items-center">
        <div className="text-5xl font-bold mr-4" style={{ maxWidth: "100%" }}>
          Wall Of Love
        </div>
        <img src={HeartIcon} alt="Heart" className="w-11 h-11" />{" "}
        {/* Adjust width and height as needed */}
      </div>
      <div className="flex flex-col items-center">
        <div className="flex items-start animate-scroll-left">
          {testimonialsTop.map((testimonial) => (
            <CommentCard
              key={testimonial.author}
              content={testimonial.content}
              author={testimonial.author}
              image={testimonial.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
