import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import CallToActionSection from "../../components/CallToActionSection";
import FAQSection from "../../components/FAQ";
import FeaturesSection from "../../components/FeaturesSection";
import Footer from "../../components/Footer";
import HeroSection from "../../components/HeroSection";
import TestimonialsSection from "../../components/TestimonialsSection";
import ToolSection from "../../components/ToolSection";
import TopBar from "../../components/TopBar";

const LandingPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // Use a slight delay to ensure the section has rendered
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  }, [location]);

  return (
    <>
      <TopBar />
      <HeroSection />
      <ToolSection />
      <FeaturesSection />
      <TestimonialsSection />
      <FAQSection />
      <CallToActionSection />
      <Footer />
    </>
  );
};

export default LandingPage;
