import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { ReactComponent as ArrowIcon } from "../assets/ArrowRight.svg";
import { ReactComponent as FooterImage } from "../assets/footerbg.svg";

const CallToActionSection: React.FC = () => {
  return (
    <div
      id="Contact"
      className="relative bg-footerbg px-36 py-32 mt-36 flex flex-col items-center justify-between"
      style={{ maxHeight: "700px", overflow: "hidden" }} // Ensure the container clips the background image if it exceeds bounds
    >
      <FooterImage
        className="mt-16 absolute inset-0 w-full h-full"
        style={{ width: "100%", height: "300px" }}
      />
      {/* Positioned as background */}
      <div className="relative text-white text-4xl font-bold text-center z-10">
        Want To Know More About Explore Ease?
      </div>
      <div className="relative text-white text-lg mt-4 text-center z-10">
        Get in touch with us, and we’ll respond promptly.
      </div>
      <Link
        to="/contact"
        className="relative bg-white hover:bg-gray-800 font-bold py-2 px-4 mt-8 rounded-3xl flex items-center justify-center z-10"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span
          style={{
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            color: "transparent",
            backgroundImage: "linear-gradient(to right, #618AC8, #75C7CC)",
          }}
        >
          Contact Us
        </span>
        <ArrowIcon className="ml-2" /> {/* Icon next to the text */}
      </Link>
    </div>
  );
};

export default CallToActionSection;
