import React, { useState } from "react";

import { ReactComponent as ToolImage } from "../assets/Vector.svg"; // Make sure this is the small vector line image

const FAQSection: React.FC = () => {
  // States to manage the visibility of each FAQ answer
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  const faqs = [
    {
      question: "What can ExploreEase AI do for me?",
      answer:
        "ExploreEase AI can help you plan a complete travel itinerary, including flight and hotel bookings, local transportation arrangements, and recommended attractions. Just tell us your destination and travel time, and our AI will provide you with a personalized travel plan.",
    },
    {
      question: "What are the costs associated with using ExploreEase AI?",
      answer:
        "The basic travel planning service of ExploreEase AI is free. We offer premium service options, such as personalized guides and special experience activities, which may incur additional costs depending on your choices.",
    },
    {
      question: "How does ExploreEase AI ensure my travel safety?",
      answer:
        "We use real-time data updates to ensure that your itinerary includes the latest weather and traffic information. Additionally, our customer service team is on standby 24/7, ready to assist with any issues you may encounter on your trip.",
    },
    {
      question: "What languages does ExploreEase AI support?",
      answer:
        "Currently, our platform supports Chinese and English. We are working to expand more languages to better serve our global users.",
    },
  ];

  const toggleFAQ = (index: number) => {
    setOpenFAQ(openFAQ === index ? null : index); // Toggle functionality
  };

  return (
    <div
      id="FAQs"
      className="faq-section flex flex-col items-center py-24 px-36 text-lg gap-16"
      style={{ width: "100%" }}
    >
      <div className="flex text-center justify-center mt-16 relative z-10">
        <div className="text-lg text-textblue" style={{ maxWidth: "100%" }}>
          FEEQUENTLY ASKED QUESTIONS
        </div>
      </div>
      <div className="flex text-center justify-center relative z-10">
        <div className="text-5xl font-bold" style={{ maxWidth: "100%" }}>
          Everything You Need To Know
        </div>
      </div>
      <div className="mb-4 flex text-center justify-center relative z-10">
        <div className="text-lg text-gray-500" style={{ maxWidth: "100%" }}>
          If you have anything else you want to ask, reach out to us.
        </div>
      </div>

      {faqs.map((faq, index) => (
        <div key={index} className="w-full">
          <div
            className="faq-question flex justify-between items-center py-4 cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <span className="text-2xl font-semibold">{faq.question}</span>
            <ToolImage className="w-6 h-6" />
          </div>
          {openFAQ === index && (
            <div className="faq-answer text-gray-600 px-4 mt-4 mb-8 pb-4">
              {faq.answer}
            </div>
          )}
          <hr className="border-gray-300" />
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
