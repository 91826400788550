import React from "react";

import { ReactComponent as Icon1Image } from "../assets/featuresIcon1.svg";
import { ReactComponent as Icon2Image } from "../assets/featuresIcon2.svg";
import { ReactComponent as Icon3Image } from "../assets/featuresIcon3.svg";

const FeaturesSection: React.FC = () => {
  return (
    <div
      className="features-section flex flex-col items-center bg-featuresbg py-12 px-36 text-xs"
      style={{
        width: "100%",
      }}
    >
      {/* Title Section */}
      <div
        className="flex text-center justify-center py-4 relative mt-24 z-10"
        style={{ lineHeight: "1.5", fontSize: "large" }} // Increased size for section title
      >
        <div className="text-xl text-textblue" style={{ maxWidth: "100%" }}>
          TRAVEL TOOL FEATURES
        </div>
      </div>
      <div
        className="flex text-center justify-center my-8 relative z-10"
        style={{ lineHeight: "2.0", fontSize: "larger" }} // Increased size for main title
      >
        <div className="text-5xl font-bold" style={{ maxWidth: "100%" }}>
          See All features
        </div>
      </div>
      {/* Features Frames */}
      <div className="mt-16 mb-16 grid grid-cols-3 gap-8 w-full px-12">
        <div className="bg-[#FFFDF8CC] rounded-lg p-6 text-center flex flex-col items-center">
          <h3 className="font-bold mt-4 mb-4 text-2xl">Custom Travel Guides</h3>
          <p
            className="text-sm leading-tight"
            style={{ height: "5.5em", overflow: "hidden" }}
          >
            Tailored travel guides with must-visit spots, dining, and
            entertainment. AI curates based on user preferences.
          </p>
          <Icon1Image className="mt-8 mb-4 w-30 h-35" />
        </div>
        <div className="bg-[#FFFDF8CC] rounded-lg p-6 text-center flex flex-col items-center">
          <h3 className="font-bold mt-4 mb-4 text-2xl">Exclusive Local Tips</h3>
          <p
            className="text-sm leading-tight"
            style={{ height: "5.5em", overflow: "hidden" }}
          >
            Insider tips on hidden gems, unique eateries, and local events. User
            reviews and ratings enhance the experience.
          </p>
          <Icon2Image className="mt-8 mb-4 w-30 h-35" />
        </div>
        <div className="bg-[#FFFDF8CC] rounded-lg p-6 text-center flex flex-col items-center">
          <h3 className="font-bold mt-4 mb-4 text-2xl">Intuitive Design</h3>
          <p
            className="text-sm leading-tight"
            style={{ height: "5.5em", overflow: "hidden" }}
          >
            Intuitive design ensures smooth navigation. Friendly tones and icons
            enhance readability.
          </p>
          <Icon3Image className="mt-8 mb-4 w-30 h-35" />
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
