import React from "react";

import StarIcon from "../assets/starIcon.svg"; // Adjust path as needed

import styles from "./CommentCard.module.css";

type CommentCardProps = {
  content: string;
  author: string;
  image: string; // URL of the user's image
};

const CommentCard: React.FC<CommentCardProps> = ({
  content,
  author,
  image,
}) => {
  return (
    <div className={styles["comment-card-wrapper"]}>
      <div className={styles["gradient-border"]}></div>
      <div className={styles["comment-card-content"]}>
        <div className="flex justify-between items-center">
          <img src={image} alt={author} className="w-12 h-12 rounded-full" />
          <img src={StarIcon} alt="Rating" className="w-30 h-30" />
        </div>
        <div className="mt-4">
          <p className="text-gray-800 text-sm mt-4">{content}</p>
          <p className="text-sm text-gray-900 font-bold mt-16">{author}</p>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
