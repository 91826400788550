import React from "react";

import { ReactComponent as Baily } from "../assets/bg.svg";
import { ReactComponent as ButtonIcon } from "../assets/buttonIcon.svg";
import { ReactComponent as CustomersIcon } from "../assets/customers.svg";

const HeroSection: React.FC = () => {
  return (
    <div
      id="hero"
      className="relative flex flex-col items-center py-12 text-xs mb-60 mt-16 w-full"
    >
      <Baily
        style={{ width: "100%", height: "800px" }}
        className="absolute top-0 left-0 bottom-0 z-0"
      />
      <div
        className="flex text-center justify-center py-4 relative z-10"
        style={{ lineHeight: "1.5" }}
      >
        <div className="text-lg text-textblue" style={{ maxWidth: "100%" }}>
          AI TRAVEL ASSISTANT
        </div>
      </div>
      <div
        className="flex text-center justify-center my-8 relative z-10"
        style={{ lineHeight: "2.0" }}
      >
        <div className="text-5xl font-bold" style={{ maxWidth: "55%" }}>
          Effortlessly Handle All Your Travel Plans
        </div>
      </div>
      <div
        className="flex text-center justify-center py-4 relative z-10"
        style={{ lineHeight: "1.5" }}
      >
        <div className="text-sm text-gray-500" style={{ maxWidth: "55%" }}>
          With ExploreEase AI, personalize your travel plans. Enter your
          destination, dates, and needs, and let it find the best options for
          flights, hotels, transport, and sightseeing.
        </div>
      </div>
      <a
        href="https://travetgpt-1-0-7abb346f60f7.herokuapp.com"
        target="_blank"
        rel="noopener noreferrer"
        className="relative z-20"
      >
        <button
          style={{
            background: "linear-gradient(to right, #618AC8, #75C7CC)",
            width: "230px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="mt-4 px-6 py-2 text-white text-lg rounded-md font-semibold flex items-center justify-center"
        >
          <ButtonIcon className="mr-2" />{" "}
          {/* Margin right for spacing between icon and text */}
          Plan Your Travel
        </button>
      </a>
      <div className="mt-16 z-10">
        <CustomersIcon />
      </div>
      {/* Insert an empty line here */}
    </div>
  );
};

export default HeroSection;
