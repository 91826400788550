import React from "react";

import ContactTopBar from "../components/ContactTopBar";
import ContactSection from "../components/ContactUs";
import Footer from "../components/Footer";

const ContactPage: React.FC = () => {
  return (
    <div id="root">
      <ContactTopBar />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default ContactPage;
