import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import ContactUsPage from "../screens/ContactUsPage";
import LandingPage from "../screens/LandingPage/LandingPage";
import NotFoundPage from "../screens/NotFoundPage";

function AppRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </HashRouter>
  );
}

export default AppRoutes;
