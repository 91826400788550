import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as NavIcon } from "../assets/NavIcon.svg";

const TopBar: React.FC = () => {
  return (
    <nav
      id="topbar"
      className="px-36 h-20 flex items-center justify-between text-sm"
    >
      <div className="flex items-center">
        <Link to="/" className="flex items-center">
          <Logo className="w-60 h-60" />
        </Link>
      </div>
      <div className="flex items-center space-x-8">
        <Link
          to="/#testimonial"
          smooth
          className="text-gray-500 hover:text-gray-900"
        >
          Testimonial
        </Link>
        <Link to="/#FAQs" smooth className="text-gray-500 hover:text-gray-900">
          FAQs
        </Link>
        <Link
          to="/#Contact"
          smooth
          className="text-gray-500 hover:text-gray-900"
        >
          Contact
        </Link>
        <NavIcon className="w-6 h-6" />
        <Link
          to="https://travetgpt-1-0-7abb346f60f7.herokuapp.com"
          style={{
            background: "linear-gradient(to right, #618AC8, #75C7CC)",
          }}
          className="h-10 px-4 text-white rounded-3xl flex items-center z-10"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get Started
        </Link>
      </div>
    </nav>
  );
};

export default TopBar;
