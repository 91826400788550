import React, { useState } from "react";

import { ReactComponent as ContactImage } from "../assets/contactus.svg";

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    phoneNumber: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      const emailRegex = /\S+@\S+\.\S+/;
      setErrors({
        ...errors,
        email: emailRegex.test(value) ? "" : "Invalid email address",
      });
    }

    if (name === "phoneNumber") {
      const phoneRegex = /^\+?\d{1,4}?\s?\d{1,4}?\s?\d{1,9}$/;
      setErrors({
        ...errors,
        phoneNumber: phoneRegex.test(value) ? "" : "Invalid phone number",
      });
    }
  };

  return (
    <div
      id="hero"
      className="relative bg-footerbg flex flex-col items-center py-12 text-xs w-full"
    >
      <div className="flex text-center justify-center relative z-10">
        <div className="text-5xl font-bold" style={{ maxWidth: "100%" }}>
          Contact Us
        </div>
      </div>
      <div className="mt-8 mb-4 flex text-center justify-center relative z-10">
        <div className="text-lg text-gray-500" style={{ maxWidth: "100%" }}>
          Any question or remarks? Just write us a message!
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-start w-full relative z-10 px-8 md:px-16 lg:px-32">
        <div className="w-full md:w-1/2 p-4 flex-shrink-0 flex items-center">
          <ContactImage style={{ width: "100%", height: "auto" }} />
        </div>
        <div className="mt-4 w-full md:w-1/2 p-4 bg-footerbg rounded-lg shadow-lg flex flex-col justify-between h-full">
          <div>
            <div className="flex flex-wrap -mx-3 mt-8 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  First Name
                </label>
                <input
                  className="appearance-none block w-full bg-footerbg text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Jane"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Last Name
                </label>
                <input
                  className="appearance-none block w-full bg-footerbg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Doe"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-footerbg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="jane.doe@example.com"
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Phone Number
                </label>
                <input
                  className="appearance-none block w-full bg-footerbg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="+1 012 3456 789"
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-xs italic">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Message
                </label>
                <textarea
                  className="appearance-none block w-full bg-footerbg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your message..."
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4 mb-4">
            <button
              style={{
                background: "linear-gradient(to right, #618AC8, #75C7CC)",
              }}
              className="w-40 h-10 px-4 text-white rounded-3xl flex items-center justify-center"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
