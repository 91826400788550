import React from "react";

import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { ReactComponent as UnionIcon } from "../assets/Union.svg";
import { ReactComponent as XIcon } from "../assets/x.svg";
import { ReactComponent as XiaohongshuIcon } from "../assets/Xiaohongshu.svg";

const Footer: React.FC = () => {
  return (
    <footer
      id="footer"
      style={{ height: "80px" }} // Fixed height for the footer
      className="bg-footerbg text-xs text-gray-400 py-4 mx-auto px-36 flex justify-between items-center"
    >
      <LogoIcon style={{ height: "25px" }} /> {/* Logo icon added */}
      <div className="flex-grow flex justify-center items-center gap-6">
        {" "}
        {/* Logo and social icons grouped together */}
        <a
          href="https://x.com/exploreease0319"
          target="_blank"
          rel="noopener noreferrer"
        >
          <XIcon style={{ height: "25px" }} />
        </a>
        <a
          href="https://www.xiaohongshu.com/user/profile/65204da1000000002402e5f5?xhsshare=CopyLink&appuid=650071ee0000000004027f7a&apptime=1717474087&share_id=8fa167489c794d83ba0f85a4b5073c11"
          target="_blank"
          rel="noopener noreferrer"
        >
          <XiaohongshuIcon style={{ height: "25px" }} />
        </a>
        <a
          href="https://weibo.com/2735096467/5025334181232881"
          target="_blank"
          rel="noopener noreferrer"
        >
          <UnionIcon style={{ height: "25px" }} />
        </a>
      </div>
      <div>
        <span>@ 2024 Explore Ease - All rights reserved</span>{" "}
        {/* Adjusted to ensure it is in one line */}
      </div>
    </footer>
  );
};

export default Footer;
