import React from "react";

import { ReactComponent as ToolImage } from "../assets/toolImage.svg";

const ToolSection: React.FC = () => {
  return (
    <div
      className="tool-section flex flex-col items-center bg-white py-12 px-36 text-xs"
      style={{
        width: "100%",
      }}
    >
      {/* Title */}
      <div
        className="flex text-center justify-center py-4 relative mt-24 z-10"
        style={{ lineHeight: "1.5", fontSize: "large" }} // Increased size for section title
      >
        <div className="text-xl text-textblue" style={{ maxWidth: "100%" }}>
          PERSONALIZED TRAVEL TOOL
        </div>
      </div>
      <div
        className="flex text-center justify-center my-8 relative z-10"
        style={{ lineHeight: "2.0", fontSize: "larger" }} // Increased size for main title
      >
        <div className="text-5xl font-bold" style={{ maxWidth: "60%" }}>
          Comprehensive Travel Planning Tailored To Your Preferences
        </div>
      </div>
      {/* Process Image */}
      <div className="mt-4 w-full">
        <ToolImage className="w-full" style={{ borderRadius: "10px" }} />
      </div>
      {/* Three-part text sections */}
      <div className="grid grid-cols-3 gap-8 w-full px-12 mt-16 mb-24">
        <div className="text-left">
          <hr className="mb-8 border-t border-gray-400" />
          <h3 className="font-bold mb-2 text-lg">Easy Conversation</h3>
          <p className="text-base">
            Just enter your destination and travel dates, and our AI assistant
            can instantly create a perfect personalized travel plan.
          </p>{" "}
        </div>
        <div className="text-left">
          <hr className="mb-8 border-t border-gray-400" />
          <h3 className="font-bold mb-2 text-lg">Detailed Travel Plan</h3>
          <p className="text-base">
            From flight bookings and selected hotels to authentic food
            recommendations and hidden sights, all your travel needs are
            covered.
          </p>{" "}
        </div>
        <div className="text-left">
          <hr className="mb-8 border-t border-gray-400" />
          <h3 className="font-bold mb-2 text-lg">Shareable Content</h3>
          <p className="text-base">
            After generating the travel plan, you can easily share it with
            friends with one click, and everyone can start the fun journey
            together!
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default ToolSection;
